import React from "react"

import Layout from "src/components/layout"
import Head from "../../components/Head"
import {graphql, useStaticQuery} from "gatsby"
import {useTranslation} from "react-i18next";
import COUNTRY from "../../constants";
import BRANDS from "../../brands";

const NotFoundPage = ({location}) => {
  const data = useStaticQuery(graphql`
    query errorPageClientsCyEn {
      site {
        siteMetadata {
          countries {
            cy {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
            }
          }
        }
      }
    }
  `)

  const {t,i18n} = useTranslation()
  i18n.changeLanguage(COUNTRY.cy_en)

  return (
    <>
      <Head
        title={t('head.404.title', { brand: BRANDS.SPORTBENEFIT.brand })}
        lang="cy_en"
        slug="/404/"
      />
      <Layout
        hotLine={data.site.siteMetadata.countries.cy.hotLine}
        location={location}
        country="cy_en"
      >
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    </>
  )
}

export default NotFoundPage
